var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',{staticClass:"card-signup header-solid h-full",staticStyle:{"margin-top":"0"},attrs:{"bordered":false,"bodyStyle":{paddingTop: 0}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h5',{staticClass:"font-semibold text-center"},[_vm._v("Sign Up")])]},proxy:true}])},[_c('div',{staticClass:"sign-up-gateways"}),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'name',
					{ rules: [{ required: true, message: 'Please input your name!' }] } ]),expression:"[\n\t\t\t\t\t'name',\n\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your name!' }] },\n\t\t\t\t\t]"}],attrs:{"placeholder":"Name"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'email',
					{ rules: [{ required: true, message: 'Please input your email!' }] } ]),expression:"[\n\t\t\t\t\t'email',\n\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your email!' }] },\n\t\t\t\t\t]"}],attrs:{"placeholder":"Email"}})],1),_c('a-form-item',{staticClass:"mb-5"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'password',
					{ rules: [{ required: true, message: 'Please input your Password!' }] } ]),expression:"[\n\t\t\t\t\t'password',\n\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your Password!' }] },\n\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"Password"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'remember',
					{
						valuePropName: 'checked',
						initialValue: true,
					} ]),expression:"[\n\t\t\t\t\t'remember',\n\t\t\t\t\t{\n\t\t\t\t\t\tvaluePropName: 'checked',\n\t\t\t\t\t\tinitialValue: true,\n\t\t\t\t\t},\n\t\t\t\t\t]"}]},[_vm._v(" I agree the "),_c('a',{staticClass:"font-bold text-dark",attrs:{"href":"#"}},[_vm._v("Terms and Conditions")])])],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" SIGN UP ")])],1)],1),_c('p',{staticClass:"font-semibold text-muted text-center"},[_vm._v("Already have an account? "),_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":"/sign-in"}},[_vm._v("Sign In")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }